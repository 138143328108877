<template>
  <validation-provider
    :rules="rules"
    :name="`«${name}»`"
    v-slot="v"
    tag="div"
    :vid="vid"
  >
    <div
      :class="{
        'hm-form__element-wrapper': true,
        'hm-form__element-wrapper--error': v.errors.length
      }"
    >
      <div class="hm-form__element-label">
        {{ placeholder }}
        <span v-if="required">*</span>
      </div>
      <esmp-input
        v-model.number="localValue"
        :options="{ type: 'number', ...options }"
        @input="onInput"
        class="input-counter"
      />
      <div v-if="v.errors.length || hintText" class="hm-form__element-hint">
        {{ v.errors.length ? v.errors[0] : hintText }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
import uid from '@/components/hm-form/mixins/uid';

export default {
  name: 'HmCounter',
  mixins: [uid],
  props: {
    value: {
      type: [String, Number],
      default: 0,
    },
    name: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    hintText: {
      type: String,
      default: undefined,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  methods: {
    onInput(val) {
      this.$emit('input', +val);
    },
  },
};
</script>
<style lang="scss" scoped>
.input-counter {
  max-width: 70px;
}
</style>
